<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Color">
            <validation-observer ref="updateColorValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Category"
                      label-for="category"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="category"
                        rules="required"
                      >
                        <b-form-select
                          v-model="category_id"
                          :options="categoryOptions"
                          @change="getBrands"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Brand"
                      label-for="brand"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="brand"
                        rules="required"
                      >
                        <b-form-select
                          v-model="brand_id"
                          :options="brandOptions"
                          :disabled="!category_id"
                          @change="getModels"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Model"
                      label-for="model"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="model"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.mobile_model_id"
                          :options="options"
                          :disabled="!brand_id"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter Color name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      category_id: null,
      brand_id: null,
      form: {
        name: '',
        mobile_model_id: null,
        image: null,
      },
      categoryOptions: [
        { value: null, text: 'Please select category', disabled: true },
      ],
      brandOptions: [
        { value: null, text: 'Please select brand', disabled: true },
      ],
      options: [
        { value: null, text: 'Please select model', disabled: true },
      ],
      currentData: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchData()
    this.loader = false
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_CATEGORY', 'SHOW_BRANDS_BY_CATEGORY', 'SHOW_MODELS_BY_BRAND', 'FETCH_SINGLE_COLOR', 'UPDATE_COLOR']),

    async fetchData() {
      try {
        this.loader = true
        const resp = await this.SHOW_ALL_CATEGORY()
        resp.forEach(element => {
          this.categoryOptions.push({ value: element.id, text: element.name })
        })
        // Fetch Color
        this.currentData = await this.FETCH_SINGLE_COLOR(this.$route.params.id)
        this.category_id = this.currentData.mobile_models.mobile_category_id
        this.brand_id = this.currentData.mobile_models.mobile_brand_id
        this.form.name = this.currentData.name
        this.form.mobile_model_id = this.currentData.mobile_model_id

        // Fetch Brands
        const brandResponse = await this.SHOW_BRANDS_BY_CATEGORY(this.category_id)
        brandResponse.forEach(element => {
          this.brandOptions.push({ value: element.id, text: element.name })
        })

        // Fetch Models
        const modelResponse = await this.SHOW_MODELS_BY_BRAND(this.brand_id)
        modelResponse.forEach(element => {
          this.options.push({ value: element.id, text: element.name })
        })

        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    async getBrands() {
      try {
        this.loader = true
        this.brandOptions = [
          { value: null, text: 'Please select brand', disabled: true },
        ]
        this.brand_id = null
        const resp = await this.SHOW_BRANDS_BY_CATEGORY(this.category_id)
        resp.forEach(element => {
          this.brandOptions.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    async getModels() {
      try {
        this.loader = true
        this.options = [
          { value: null, text: 'Please select model', disabled: true },
        ]
        this.form.mobile_model_id = null
        const resp = await this.SHOW_MODELS_BY_BRAND(this.brand_id)
        resp.forEach(element => {
          this.options.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updateColorValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const formData = new FormData()
            if (this.currentData.name !== this.form.name) {
              formData.append('name', this.form.name)
            }
            formData.append('mobile_model_id', this.form.mobile_model_id)
            if (this.form.image) {
              formData.append('image', this.form.image)
            }
            const resp = await this.UPDATE_COLOR({ id: this.$route.params.id, data: formData })

            if (resp) {
              this.$nextTick(() => {
                this.$refs.updateColorValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Color Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'Color has been updated successfully!',
                },
              })
              this.$router.push('/mobile-tablet/colors')
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
